import React from "react";
import PageWelcomeBanner from "../Components/web/SubComponents/PageWelcomeBanner";
import GalleryPanel from "../Components/web/GalleryPanel";

const Gallery = ({gallerydb ,logo_banner}) => {
  return <div>
    <PageWelcomeBanner currentPage={"GALLERY"} logo_banner={logo_banner} />
    <GalleryPanel gallerydbData={gallerydb}  />
  </div>;
};

export default Gallery;
