import React from 'react'
import "../../All_css/JourneyPanel.css"

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default function JourneyPanel({ journeyData }) {

  const month= ["","January", "February", "March", "April", "May", "June", "July", "August","September", "October", "November", "December"];

  return (

    <div className='container_div' style={{paddingTop:"40px"}}>
      <div className='home_page_heading'>
        {/* <h3>Journey</h3> */}
        <h1>Our Journey</h1>
      </div>
      <VerticalTimeline> 

        
        {
          journeyData.map((value,index)=>{

            let SplitDate = value.title.split("-"); 
            // let custoemDate = SplitDate[2] +" "+ month[Number.parseInt(SplitDate[1])] +" "+  SplitDate[0]
            let custoemDate = SplitDate[0]
            return (
              <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work journey_panel_ journey_vertical_timeline_element"
              contentStyle={{ background: '#3c486b', color: '#fff' }}
              contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
              date={custoemDate}
              iconStyle={{ background: '#3c486b', color: '#fff' }}
            // icon={<WorkIcon />}

            >
            <img src={value.img_path} className='journey_panel_journey_img' alt="" />
              <p>{value.description}</p>
            </VerticalTimelineElement> 
            )
          })
        }
      

  
        <VerticalTimelineElement
          iconStyle={{ background: '#3c486b', color: '#fff' }}
        // icon={<StarIcon />}
        />
      </VerticalTimeline>
    </div>
  )
}
