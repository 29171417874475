// import React from "react";
// import Carousel from 'nuka-carousel';

// export default function ImsgSlider({ dbBannerData }) {
//     return (
//         <div >
//             <Carousel style={{ zIndex: "-2" }}>

//                 {
//                     dbBannerData.map((value, index) => {
//                         return (
//                             <img key={index} className="carouselImage" src={value.img_path} alt="slider_image" />
//                         )
//                     })
//                 }
//             </Carousel>
//         </div>
//     );
// }

import React, { useEffect, useState } from "react";
import "../../All_css/imageslider.css";
import leftArrow from "../../my_assets/arrows/left_arrow_img.png";
import rightArrow from "../../my_assets/arrows/right_arrow_img.png";
 
export default function ImsgSlider({ dbBannerData }) {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dbBannerData.length * 100 - 100 > currentImage) {
        setCurrentImage(currentImage + 100);
      } else {
        setCurrentImage(-100);
      }
      // alert("good");
    }, 5000);
    return () => clearInterval(interval);
  }, [currentImage, dbBannerData]);

  function handleLeftMove() {
    if (currentImage === 0) {
      setCurrentImage(dbBannerData.length * 100 - 100);
    } else {
      setCurrentImage(currentImage - 100);
    }
  }

  function handleRightMove() {
    if (dbBannerData.length * 100 - 100 > currentImage) {
      setCurrentImage(currentImage + 100);
    } else {
      setCurrentImage(0);
    }
  }

  return (
    <div className="slide-container">
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          transition: "500ms",
          marginLeft: `-${currentImage}vw`,
        }}
      >
        {dbBannerData.map((dbBannerData, index) => {
          return (
            <div key={index}>
              {window.visualViewport.width > 768 ? (
                <div
                  className="handle_image_size"
                  style={{ backgroundImage: `url(${dbBannerData.img_path})` }}
                >
                  {/* <img
                    key={index}
                    src={dbBannerData.img_path}
                    style={{
                      display: "block",
                      overflow: "hidden",
                      height: "100vh",
                      margin: "auto",
                    }}
                    alt=""
                  /> */}
                </div>
              ) : (
                <div
                  className="handle_image_size"
                  style={{
                    backgroundImage: `url(${dbBannerData.img_path_mob})`,
                  }}
                >
                  {/* <img
                  key={index}
                  src={dbBannerData.img_path}
                  style={{
                    display: "block",
                    overflow: "hidden",
                    height: "100vh",
                    margin: "auto",
                  }}
                  alt=""
                /> */}
                </div>
                // <div className="handle_image_size">
                //   <img
                //     key={index}
                //     src={dbBannerData.img_path_mob}
                //     style={{ overflow: "hidden", height: "100vh" }}
                //     alt=""
                //   />
                // </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="main-banner">
        {dbBannerData.map((dbBannerData, index) => {
          return (
            <i
              key={index}
              style={{
                background: `${
                  currentImage / 100 === index
                    ? "#eadedd"
                    : "rgba(255, 255, 255, 0.333)"
                }`,
              }}
            ></i>
          );
        })}
      </div>
      <div className="main-banner-navigator">
        <i onClick={handleLeftMove}>
          <img src={leftArrow} alt="" />
        </i>
        <i onClick={handleRightMove}>
          <img src={rightArrow} alt="" />
        </i>
      </div>
    </div>
  );
}
