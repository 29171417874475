import React from "react";
import "../../All_css/GalleryPanel.css";

export default function GalleryPanel({ gallerydbData }) {
  return (
    <div className="container_div">
      <div className="home_page_heading">
        {/* <h3>Gallery</h3> */}
        <h1>Our Gallery</h1>
      </div>
      <div className="galley_image">
        {gallerydbData.map((value, index) => {
          return (
            <div
              style={{
                backgroundImage: `url(${value.img_path})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            >
              {/* <img key={index} src={value.img_path} alt="gallery_image" /> */}
            </div>
          );
        })}
      </div>
    </div>
  );
}
