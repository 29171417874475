import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../All_css/Navbar.css";
import logo from "../my_assets/app_requirements/Baba New PNG.png";

export default function Navbar({ navData, setcurrentPage ,logo_banner}) {
  const [showNav, setShowNav] = useState(false);
  //    =======================================================================================================    navbar styling
  function shownav() {
    showNav ? setShowNav(false) : setShowNav(true);
    document.body.classList.toggle("black-semi-transparent");
  }

  function closenav() {
    setShowNav(false);
    document.body.classList.toggle("black-semi-transparent");
  }

  //=======================================================================================================  navbar styling end

  function handleClick(pageName) {
    setcurrentPage(pageName);
    sessionStorage.setItem("currentPage", pageName);
    window.scrollTo(0, 0);
    setShowNav(false);
    document.body.classList.remove("black-semi-transparent");
  }
  return (
    <div id="nav1-navbar-main">
      <div className="nav1-navbar-main-conatiner">
        <div id="nav1-navbar">
          <nav id="nav1-nav">
            <div className={"nav1-a1"}>
              <div className="Logo-menu-div">
                <img
                  onClick={(e) => shownav()}
                  src={"assets/app_assets/hamberger_menu.png"}
                  className="hamburger-menu"
                  alt=""
                />
                <img src={logo_banner && logo_banner.logo_img} alt="" className="main-navbar-logo" />
              </div>
            </div>
            <div
              className={
                showNav ? "nav1-a2 nav1-shownav" : "nav1-a2 nav1-hidenav"
              }
              onClick={(e) => closenav()}
            >
              <ul onClick={(e) => e.stopPropagation()}>
                <li>
                  <Link onClick={() => handleClick("HOME")} to="/">
                    HOME
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleClick("JOURNEY")} to="/journey">
                    JOURNEY
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => handleClick("MEDIA COVERAGE")}
                    to="/media_coverage"
                  >
                    MEDIA COVERAGE
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => handleClick("ONLINE DARBAR")}
                    to="/drkirodilalapp"
                  >
                    Dr Kirodilal App 
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleClick("EVENTS")} to="/events">
                    Events
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => handleClick("GOVERNANCE")}
                    to="/governance"
                  >
                    GOVERNANCE
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleClick("ISSUES")} to="/issues">
                    ISSUES
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleClick("GALLERY")} to="/gallery">
                    GALLERY
                  </Link>
                </li>
                <li>
                  <Link onClick={() => handleClick("BLOG")} to="/blog">
                    BLOG
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
