import React from "react";
import "../All_css/Footer.css";
import { Link } from "react-router-dom";
import logo from "../my_assets/app_requirements/Baba New PNG.png";

export default function Footer({ footerData, socialMediaLinks, logo_banner }) {
  function handleClick() {
    window.scrollTo(0, 0);
  }

  function handlePageTitle(title) {
    window.document.head.children[9].textContent = title;
    console.log("title changed");
  }

  return (
    <div>
      <section>
        <footer id="myFooter-1">
          <div className="b1">
            {/* <h3>{footerData.name}</h3> */}
            <img
              style={{ width: "250px", padding: "5px 0px" }}
              src={logo_banner && logo_banner.logo_img}
              alt=""
            />
            <span>
              {footerData &&
                footerData.description &&
                footerData.description.substr(0, 100)}
              {handlePageTitle(
                footerData && footerData.name && footerData.name
              )}
            </span>
            <Link onClick={() => handleClick()} to="journey">
              {" "}
              <button>READ MORE</button>
            </Link>
          </div>

          <div className="b3">
            <h5 className="footerh5">Social</h5>
            <ul>
              {socialMediaLinks.map((value, index) => {
                return (
                  <li key={index}>
                    <a href={value.description}>{value.title}</a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="b4">
            <h5 className="footerh5">Useful Links</h5>
            <ul>
              <li>
                <Link onClick={() => handleClick()} to={"/"}>
                  Home
                </Link>
              </li>
              <li>
                <Link onClick={() => handleClick()} to={"/journey"}>
                  JOURNEY
                </Link>
              </li>
              <li>
                <Link onClick={() => handleClick()} to={"/media_coverage"}>
                  MEDIA COVERAGE
                </Link>
              </li>
              <li>
                <Link onClick={() => handleClick()} to={"/online_darbar"}>
                  ONLINE DARBAR
                </Link>
              </li>
              <li>
                <Link onClick={() => handleClick()} to={"/governance"}>
                  GOVERNANCE
                </Link>
              </li>
              <li>
                <Link onClick={() => handleClick()} to={"/issues"}>
                  ISSUES
                </Link>
              </li>
              <li>
                <Link onClick={() => handleClick()} to={"/gallery"}>
                  GALLERY
                </Link>
              </li>
            </ul>
          </div>
          <div className="b4">
            <h5 className="footerh5">About us</h5>
            <ul>
              <li>
                <Link onClick={() => handleClick()} to={"/media_coverage"}>
                  News
                </Link>
              </li>

              <li>
                <Link onClick={() => handleClick()} to={"/blog"}>
                  BLOG
                </Link>
              </li>
              <li>
                <Link onClick={() => handleClick()} to={"/privacy_policy"}>
                  Privacy Policy for Website
                </Link>
              </li> 
             <li>
                <Link onClick={() => handleClick()} to={"/dr_kirodilal_app_privacy_policy"}>
                  Privacy Policy for Dr Kirodi Lal App
                </Link>
              </li>
             <li>
                <Link onClick={() => handleClick()} to={"/account_and_data_delete_request"}>
                  Request to Delete Account Data
                </Link>
              </li>
            </ul>
          </div>
          <div className="b4">
            <h5 className="footerh5">Newsletter</h5>
            <span>Connect With Dr Kirodilal</span>
            <Link onClick={() => handleClick()} to="online_darbar">
              {" "}
              <button>sign up</button>
            </Link>
          </div>
        </footer>
        <div className="copyright_message">
          <h4>
            @2023{" "}
            <a style={{ textDecoration: "none" }} href="https://ntpl.world/">
              NTPL.WORLD
            </a>
          </h4>
        </div>
      </section>
    </div>
  );
}
