import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../All_css/BlogPanel.css";
// import "../../All_css/blogpanel2.css"
import "../../All_css/pagination.css";
import ReactPaginate from "react-paginate";

export default function BlogPanel({ blogsdbdata, paginateStatus }) {
  // Example items, to simulate fetching from other resources.
  const items = blogsdbdata;
  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      //   console.log(
      //     `User requested page number ${event.selected}, which is offset ${newOffset}`
      //   );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        {paginateStatus && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            className="react-paginate-navigator"
            onClick={window.scroll(0,0)}
          />
        )}
      </>
    );
  }

  function Items({ currentItems }) {
    return (
      <div style={{ backgroundColor: "#f0f0f0", padding: "1px 0" }}>
        <div className="container_div">
          <div className="home_page_heading">
            {/* <h3>Blogs</h3> */}
            <h1>Our Blogs</h1>
          </div>
          <div id="blogpage-card-view-box-1">
            {currentItems.map((value, index) => {
              return (
                <div className="card-box" key={index}>
                  <div className="card-image-div">
                    <img src={value.img_path} alt="" />
                  </div>
                  <div className="blobdetail_box">
                    <div className="blogger_details">
                      <img src={value.blogger_image} alt="" />
                      <h6 style={{ fontSize: "1rem",margin:"0px 12px",fontFamily:`"Open Sans", sans-serif` }}>{value.blogger_name}</h6>
                      <h6 style={{ fontSize: "0.9rem",marginLeft:"0px 12px",marginBottom:"0px",fontFamily:`"Open Sans", sans-serif` }}>
                        {value.date.slice(0, 10)}
                      </h6>
                    </div>
                    <div className="card-description">
                      {/* <h4>Blog Category</h4> */}
                      <h4 style={{ color: "#3c486b",fontWeight:"600" }}>{value.title}</h4>
                      <p style={{fontSize:".9rem"}}>{value.description.substr(0,150)}</p>
                      <Link to={`/blog_view?blogId=${value._id}`} onClick={()=>{window.scroll(0,0)}}>
                      <button className="read_more_blog">
                        Read More
                      </button>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <PaginatedItems itemsPerPage={6} />
    </>
  );

}
