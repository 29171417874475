import React, { useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Routing from "./Routing";
import logo from "../my_assets/app_requirements/Baba New PNG.png";

export default function MasterLayout() {
  const [footerData, setfooterData] = useState("");
  const [currentPage, setcurrentPage] = useState("");
  const [socialMediaLinks, setsocialMediaLinks] = useState([]);
  const [loading, setloading] = useState(true);
  const [logo_banner, setlogo_banner] = useState([]);

  setTimeout(() => {
    setloading(false);
  }, 2000);
  return (
    <div>
      {/* ============================================================================ */}
      {/* loader for 2 second start */}
      <div
        className="main-app-master-layout-transition"
        style={loading ? { display: "block" } : { display: "none" }}
      >
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h4
            style={{
              display: "block",
              color: "#0026ff",
              textDecoration: "underline",
              padding: "30px",
            }}
          >
            {/* {window.location.host} */}
            <img
              style={{
                width: "300px",
                background: "#475284",
                padding: "30px",
                borderRadius: "10px",
              }}
              src={logo}
              alt=""
            />
          </h4>
          <br />
          <br />
          <p> Loading ... </p>
        </div>
      </div>
      {/* loader for 2 second ends */}
      {/* ============================================================================ */}

      {/* ============================================================================ */}

      {/* main   body    to start  */}
      <div
        className="main-app-master-layout-transition"
        style={loading ? { display: "none" } : { display: "block" }}
      >
        <Navbar
          navData={footerData}
          setcurrentPage={setcurrentPage}
          logo_banner={logo_banner}
        />

        <Routing
          setfooterData={setfooterData}
          setsocialMediaLinks={setsocialMediaLinks}
          setlogo_banner={setlogo_banner}
          logo_banner={logo_banner}
        />
        <Footer
          footerData={footerData}
          socialMediaLinks={socialMediaLinks}
          logo_banner={logo_banner}
        />
      </div>
      {/* main   body    to start  */}
      {/* ============================================================================ */}
    </div>
  );
}
