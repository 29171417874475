/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import "../../All_css/imageslider.css";
import "../../All_css/issueHomePanel.css";
import leftArrow from "../../my_assets/arrows/left_arrow_img.png";
import rightArrow from "../../my_assets/arrows/right_arrow_img.png";
import { Link } from "react-router-dom";

export default function IssueHomePanel({ issuesdbdata }) {
  const [currentImage, setCurrentImage] = useState(0);

  let [handleImageSize, sethandleImageSize] = useState(33.333333);
  useEffect(() => {
    if (window.visualViewport.width <= 700) {
      sethandleImageSize(100);
    }
    if (
      window.visualViewport.width > 1050 &&
      window.visualViewport.width <= 1400
    ) {
      sethandleImageSize(33.333333333333333333333);
    }
    if (
      window.visualViewport.width > 700 &&
      window.visualViewport.width <= 1050
    ) {
      sethandleImageSize(50);
    }
    if (window.visualViewport.width > 1400) {
      sethandleImageSize(25);
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (issuesdbdata.length * handleImageSize - 100 > currentImage) {
        setCurrentImage(currentImage + 100);
      } else {
        setCurrentImage(-100);
      }
      // alert("good");
    }, 5000);
    return () => clearInterval(interval);
  }, [currentImage, handleImageSize, issuesdbdata]);

  function handleLeftMove() {
    if (currentImage === 0) {
      setCurrentImage(issuesdbdata.length * handleImageSize - 100);
    } else {
      setCurrentImage(currentImage - 100);
    }
  }

  function handleRightMove() {
    if (issuesdbdata.length * handleImageSize - 100 > currentImage) {
      setCurrentImage(currentImage + 100);
    } else {
      setCurrentImage(0);
    }
  }

  return (
    <div className="slide-container">
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          transition: "500ms",
          marginLeft: `-${currentImage}vw`,
        }}
      >
        {issuesdbdata.map((issuesdbdata, index) => {
          return (
            <div key={index} className="issue-message">
              {window.visualViewport.width > 768 ? (
                <img
                  key={index}
                  src={issuesdbdata.img_path}
                  className="issue-image-size"
                  alt=""
                />
              ) : (
                <img
                  key={index}
                  src={issuesdbdata.img_path_mob}
                  style={{ width: "100vw", height: "100vh" }}
                  alt=""
                />
              )}
              {/* ===================  issue content -start ================================================================== */}
              <div className="issue-message-box">
                <div className="issue-title">{issuesdbdata.title}</div>
                <div className="issue-description">
                  {issuesdbdata.description}
                </div>
              </div>
              {/* ===================  issue content -start ================================================================== */}
            </div>
          );
        })}
      </div>
      {/* <div className="main-banner">
        {issuesdbdata.map((issuesdbdata, index) => {
          return (
            <i
              key={index}
              style={{
                background: `${
                  currentImage / 100 === index
                    ? "#eadedd"
                    : "rgba(255, 255, 255, 0.333)"
                }`,
              }}
            ></i>
          );
        })}
      </div> */}
      {/* =========================  arrow   -start ======================================== */}
      <div className="main-banner-navigator">
        <i onClick={handleLeftMove}>
          <img src={leftArrow} alt="" />
        </i>
        <i onClick={handleRightMove}>
          <img src={rightArrow} alt="" />
        </i>
      </div>
      {/* =========================  arrow  -ends ======================================== */}

      {/* =========================  read more btn  -start ======================================== */}
      <div className="issue-read-btn">
        <div className="section_description">
          Issue Solved
        </div>
        <Link
          to="/issues"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <button>Read More</button>
        </Link>
      </div>
      {/* =========================  read more btn  -end  ======================================== */}
    </div>
  );
}
