import React, { useEffect } from "react";
import leftArrow from "../../../my_assets/arrows/left_arrow_img.png";
import rightArrow from "../../../my_assets/arrows/right_arrow_img.png";
import { useState } from "react";
import "../../../All_css/newGovernencePanel.css";

const GovernenceMovePage = ({ value, index }) => {
  let totallength = 0;
  const [imageMargin, setimageMargin] = useState(0);
  let images = [
    "img_1",
    "img_2",
    "img_3",
    "img_4",
    "img_5",
    "img_6",
    "img_7",
    "img_8",
    "img_9",
    "img_10",
  ];
  let images_mobile = [
    "img_1_mob",
    "img_2_mob",
    "img_3_mob",
    "img_4_mob",
    "img_5_mob",
    "img_6_mob",
    "img_7_mob",
    "img_8_mob",
    "img_9_mob",
    "img_10_mob",
  ];

  // window.visualViewport.width

  function handleRightMove(e) {
    if (totallength - 100 > imageMargin) {
      e.target.parentElement.parentElement.parentElement.children[0].children[0].style.marginLeft = `-${
        imageMargin + 100
      }vw`;
      setimageMargin(imageMargin + 100);
    } else {
      setimageMargin(0);
      e.target.parentElement.parentElement.parentElement.children[0].children[0].style.marginLeft = 0;
    }
  }
  function handleLeftMove(e) {
    if (imageMargin > 0) {
      e.target.parentElement.parentElement.parentElement.children[0].children[0].style.marginLeft = `-${
        imageMargin - 100
      }vw`;
      setimageMargin(imageMargin - 100);
    } else {
      e.target.parentElement.parentElement.parentElement.children[0].children[0].style.marginLeft = `-${
        totallength - 100
      }vw`;
      setimageMargin(totallength - 100);
    }
  }

  return (
    <div key={index} className="maingv">
      <div className="slide_section">
        <div className="sliderImages">
          {/* desktop view */}
          {window.visualViewport.width > 800 &&
            images.map((data, dataindex) => {
              if (
                value[data] !== "" &&
                value[data] !== undefined &&
                value[data].includes("http")
              ) {
                totallength += 100;
                return (
                  // handle_image_size_percent
                  <div
                    className="handle_image_size"
                    style={{ backgroundImage: `url(${value[data]})` }}
                  ></div>
                  // <img
                  //   key={dataindex}
                  //   src={value[data]}
                  //   alt={`img ${dataindex + 1}`}
                  // />
                );
              } else {
                return "";
              }
            })}

          {/* mobile view */}
          {window.visualViewport.width <= 800 &&
            images_mobile.map((data, dataindex) => {
              if (
                value[data] !== "" &&
                value[data] !== undefined &&
                value[data].includes("http")
              ) {
                totallength += 100;
                return (
                  <div
                    className="handle_image_size"
                    style={{ backgroundImage: `url(${value[data]})` }}
                  ></div>
                  // <img
                  //   key={dataindex}
                  //   src={value[data]}
                  //   alt={`img ${dataindex + 1}`}
                  // />
                );
              } else {
                return "";
              }
            })}
        </div>
      </div>
      <div className="sliderdata">
        <div className="slidergv_title">{value.title}</div>
        <div className="slidergv_description">{value.description}</div>
      </div>
      <div className="sliderArrows">
        <span>
          <img
            onClick={(e) => {
              handleLeftMove(e);
            }}
            src={leftArrow}
            alt=""
          />
        </span>
        <span>
          <img
            onClick={(e) => {
              handleRightMove(e);
            }}
            src={rightArrow}
            alt=""
          />
        </span>
      </div>
    </div>
  );
};

export default GovernenceMovePage;
