import React from "react";
import PageWelcomeBanner from "../Components/web/SubComponents/PageWelcomeBanner";
// import GovernancePanel from "../Components/web/GovernancePanel";
import NewGovernancePanel from "../Components/web/NewGovernancePanel";

const Issue = ({ all_issuesdb ,logo_banner}) => {
  return (
    <div>
      <PageWelcomeBanner currentPage={"Issues"} logo_banner={logo_banner} />
      {/* <GovernancePanel  issuesdbdata={issuesdb} /> */}
      <div className="home_page_heading">
        {/* <h3>Issues</h3> */}
        <h1>Issues Solved</h1>
      </div>
      <NewGovernancePanel governancedb={all_issuesdb} />
    </div>
  );
};

export default Issue;