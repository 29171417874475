import React, { useState } from "react";
import "../../All_css/AccountDeleteRequest.css";

const AccountDeleteRequest = () => {
  const [number, setnumber] = useState("");
  const [otp, setopt] = useState("");
  const handleDelete = async (e) => {
    e.preventDefault();
    if (number.length !== 10) {
      return alert("Please enter a valid mobile number.");
    }
    try {
    //   console.log(
    //     `${process.env.REACT_APP_SERVERPORT2}/${sessionStorage.getItem(
    //       process.env.REACT_APP_USERNONECLIMATE
    //     )}/delete/${number}`
    //   );
      fetch(
        `${process.env.REACT_APP_SERVERPORT2}/${sessionStorage.getItem(
          process.env.REACT_APP_USERNONECLIMATE
        )}/delete/${number}`
      )
        .then((v) => {
          return v.json();
        })
        .then((v) => {
          if (v.message === "success") {
            alert("account data delete request send successfully.");
            setnumber('')
          } else {
            alert("something went wrong, please try again.");
          }
          console.log(v);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(error.message);
    }
  };
  return (
    <div className="container_div" style={{ paddingTop: "40px" }}>
      <div className="home_page_heading">
        {/* <h3>Journey</h3> */}
        <h1>Account or Data delete request</h1>
      </div>
      <div className="delete-account-form">
        <form
          onSubmit={(e) => {
            handleDelete(e);
          }}
        >
          <div className="mb-3">
            <label className="form-label">Mobile Number</label>
            <input
              type="number"
              value={number}
              onChange={(e) => {
                setnumber(e.target.value);
              }}
              className="form-control"
              id="exampleInputEmail1"
            />
          </div>

          {/* <div className="mb-3">
            <label className="form-label">Otp</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputEmail1"
            />
          </div> */}

          <button type="submit" className="btn btn-primary">
            Send Request
          </button>
        </form>
      </div>
    </div>
  );
};

export default AccountDeleteRequest;
