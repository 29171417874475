/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import "../../All_css/imageslider.css";
import "../../All_css/JourneyHomePanel.css";
import leftArrow from "../../my_assets/arrows/left_arrow_img.png";
import rightArrow from "../../my_assets/arrows/right_arrow_img.png";
import { Link } from "react-router-dom";

export default function JourneyHomePanel({ Journeydb }) {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Journeydb.length * 100 - 100 > currentImage) {
        setCurrentImage(currentImage + 100);
      } else {
        setCurrentImage(0);
      }
      // alert("good");
    }, 5000);
    return () => clearInterval(interval);
  }, [currentImage, Journeydb]);

  function handleLeftMove() {
    if (currentImage === 0) {
      setCurrentImage(Journeydb.length * 100 - 100);
    } else {
      setCurrentImage(currentImage - 100);
    }
  }

  function handleRightMove() {
    if (Journeydb.length * 100 - 100 > currentImage) {
      setCurrentImage(currentImage + 100);
    } else {
      setCurrentImage(0);
    }
  }

  return (
    <div className="slide-container">
      {/* ===================  journey image -start ================================================================== */}
      <div
        style={{
          display: "flex",
          overflow: "hidden",
          transition: "500ms",
          marginLeft: `-${currentImage}vw`,
        }}
      >
        {Journeydb.map((Journeydb, index) => {
          return (
            <div key={index} className="journey-message">
              {window.visualViewport.width > 768 ? (
                // <img
                //   key={index}
                //   src={Journeydb.img_path}
                //   style={{ overflow:"hidden", height: "100vh" }}
                //   alt=""
                // />
                <div
                  className="handle_image_size"
                  style={{ backgroundImage: `url(${Journeydb.img_path})` }}
                ></div>
              ) : (
                // <img
                //   key={index}
                //   src={Journeydb.img_path_mob}
                //   style={{ overflow:"hidden", height: "100vh" }}
                //   alt=""
                // />
                <div
                  className="handle_image_size"
                  style={{ backgroundImage: `url(${Journeydb.img_path_mob})` }}
                ></div>
              )}

              {/* ===================  journey content -start ================================================================== */}
              <div className="journey-message-box">
                <div className="journey-title">OUR JOURNEY</div>
                <div className="journey-timeline">{Journeydb.title}</div>
                <div className="journey-description">
                  {Journeydb.description.substr(0, 100)}
                </div>
                <div className="journey-read-btn">
                  <Link
                    to="/journey"
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <button>Read More</button>
                  </Link>
                </div>
              </div>
              {/* ===================  journey content -start ================================================================== */}
            </div>
          );
        })}
      </div>
      {/* ===================  journey image -ends ================================================================== */}

      {/* ===================  journey Navigation -start ================================================================== */}
      <div className="main-journye-timeline-nav">
        {Journeydb.map((value, index) => {
          return (
            <span
              key={index}
              onClick={() => {
                setCurrentImage(index * 100);
              }}
              className="timeline-span"
            >
              {value.title}
            </span>
          );
        })}
      </div>
      {/* ===================  journey Navigation -ends ================================================================== */}

      {/* ===================  arrows ================================================================== */}
      {/* <div className="main-journey-navigator">
        <i onClick={handleLeftMove}>
          <img src={leftArrow} alt="" />
        </i>
        <i onClick={handleRightMove}>
          <img src={rightArrow} alt="" />
        </i>
      </div> */}
      {/* ===================  arrows ================================================================== */}
    </div>
  );
}
