import React from 'react'
// import GovernancePanel from '../Components/web/GovernancePanel' 
import NewGovernancePanel from '../Components/web/NewGovernancePanel'
import PageWelcomeBanner from '../Components/web/SubComponents/PageWelcomeBanner'

export default function Governance({governancedb ,logo_banner}) {
  return (
    <div>
      <PageWelcomeBanner currentPage={"GOVERNANCE"} logo_banner={logo_banner} />
      <div className="home_page_heading">
        {/* <h3>Governance</h3> */}
        <h1>Our Governance</h1>
      </div>
      {/* <GovernancePanel governancedb={governancedb} /> */}
      <NewGovernancePanel governancedb={governancedb} />
    </div>
  )
} 
