import React, { useState } from "react";
import "../../All_css/OnlineDarbarPanel.css";
import FormData from "form-data";
import mobile_image from "../../my_assets/app_requirements/dumyMobileImg.png";
import { countries } from "../countryList";
import { Link } from "react-router-dom/dist";

export default function BlackOnlineDarbar({ adminAboutdb }) {
  const [name, setname] = useState("");
  const [middle_name, setmiddle_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [gender, setgender] = useState("Male");
  const [email, setemail] = useState("");
  const [contact_number, setcontact_number] = useState("");
  const [WhatsApp_No, setWhatsApp_No] = useState("");
  const [Country, setCountry] = useState("");
  const [State, setState] = useState("");
  const [District, setDistrict] = useState("");
  const [Tahsil, setTahsil] = useState("");
  const [City_Block, setCity_Block] = useState("");
  const [Village_Colony, setVillage_Colony] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [checked, setChecked] = useState(false);

  function handleChange(e) {
    setChecked(e.target.checked);
  }
  function handleSubmit(e) {
    e.preventDefault();

    if (!checked) {
      return alert("Please Accept privacy Policy");
    }
    const store = new FormData();
    store.set("_name", name);
    store.set("_middle_name", middle_name);
    store.set("_last_name", last_name);
    store.set("_gender", gender);
    store.set("_email", email);
    store.set("_contact_number", contact_number);
    store.set("_WhatsApp_No", WhatsApp_No);
    store.set("_Country", Country);
    store.set("_State", State);
    store.set("_District", District);
    store.set("_Tahsil", Tahsil);
    store.set("_City_Block", City_Block);
    store.set("_Village_Colony", Village_Colony);
    store.set("_PinCode", PinCode);
    store.set(
      "_client_id",
      sessionStorage.getItem(process.env.REACT_APP_USERNONECLIMATE)
    );

    console.log(
      `${process.env.REACT_APP_SERVERPORT2}/store_user_web_form/${process.env.REACT_APP_STOREKEY}`
    );

    fetch(
      `${process.env.REACT_APP_SERVERPORT2}/store_user_web_form/${process.env.REACT_APP_STOREKEY}`,
      {
        method: "POST",
        body: store,
      }
    )
      .then((value) => {
        // console.log(value);
        return value.json();
      })
      .then((v) => {
        if (v.message === "success" || v.message === "user_exists") {
          alert("Your request posted successfully.");
          setname("");
          setmiddle_name("");
          setlast_name("");
          setgender("Male");
          setemail("");
          setcontact_number("");
          setWhatsApp_No("");
          setCountry("");
          setState("");
          setDistrict("");
          setTahsil("");
          setCity_Block("");
          setVillage_Colony("");
          setPinCode("");
          setChecked(false);
          // window.scroll(0,0)
        }
        if (v.message === "mobile_exists") {
          alert("Mobile Number already exists, Please use another one.");
        }
        if (v.message === "err") {
          alert("Something went wront, Please try again.");
        }
      });
  }

  return (
    <div className="container_div black2">
      <div className="home_page_heading">
        <h3 className="color2">Dr Kirodilal</h3>
        <h1 className="color1">We Know Janta First</h1>
      </div>
      <div>
        <form onSubmit={handleSubmit} className="onlineDarbarMainForm">
          <div id="about_div1" className="onlineDarbar_div1 black2">
            <div className="grid-section1">
              <img src={mobile_image} alt="" />
            </div>
            <div className="grid-section2">
              <div>
                <input
                  type="text"
                  placeholder="First Name"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                />
                <input
                  type="text"
                  placeholder="MIddle Name"
                  value={middle_name}
                  onChange={(e) => {
                    setmiddle_name(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1   half-input"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={last_name}
                  onChange={(e) => {
                    setlast_name(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1   half-input"
                />

                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setemail(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                />
                <select
                  type="text"
                  placeholder="Gender"
                  value={gender}
                  onChange={(e) => {
                    setgender(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                >
                  {["Male", "Female", "Other"].map((value, index) => {
                    return (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <input
                  type="text"
                  placeholder="Contact Number"
                  value={contact_number}
                  onChange={(e) => {
                    setcontact_number(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                />

                <input
                  type="text"
                  placeholder="Watsapp Number"
                  value={WhatsApp_No}
                  onChange={(e) => {
                    setWhatsApp_No(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                />
                {/* ================================================================================ */}

                <select
                  type="text"
                  placeholder="Select Country"
                  value={Country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                    // setState(countries[Country][0]);
                    countries[e.target.value] &&
                    countries[e.target.value] !== ""
                      ? setState(Object.keys(countries[e.target.value])[0])
                      : setState("");
                    countries[e.target.value] &&
                    countries[e.target.value][
                      Object.keys(countries[e.target.value])[0]
                    ] &&
                    countries[e.target.value][
                      Object.keys(countries[e.target.value])[0]
                    ] !== ""
                      ? setDistrict(
                          countries[e.target.value][
                            Object.keys(countries[e.target.value])[0]
                          ][0]
                        )
                      : setDistrict("");
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                >
                  <option>Select Country</option>
                  {Object.keys(countries).map((value, index) => {
                    return (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <select
                  type="text"
                  placeholder="Select State"
                  value={State}
                  onChange={(e) => {
                    setState(e.target.value);
                    countries[Country] &&
                    countries[Country][e.target.value] &&
                    countries[Country][e.target.value] !== ""
                      ? setDistrict(countries[Country][e.target.value][0])
                      : setDistrict("");
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                >
                  {countries[Country] &&
                    countries[Country] !== "" &&
                    Object.keys(countries[Country]).map((value, index) => {
                      return (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      );
                    })}
                </select>
                <select
                  type="text"
                  placeholder="Select District"
                  value={District}
                  onChange={(e) => {
                    setDistrict(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                >
                  {countries[Country] &&
                    countries[Country][State] &&
                    countries[Country][State].map((value, index) => {
                      return (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      );
                    })}
                </select>
                {/* ================================================================================ */}
                <input
                  type="text"
                  placeholder="Select Tahsil"
                  value={Tahsil}
                  onChange={(e) => {
                    setTahsil(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                />
                <input
                  type="text"
                  placeholder="Select city / Block"
                  value={City_Block}
                  onChange={(e) => {
                    setCity_Block(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                />
                <input
                  type="text"
                  placeholder="Village / Colony"
                  value={Village_Colony}
                  onChange={(e) => {
                    setVillage_Colony(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                />
                <input
                  type="text"
                  placeholder="Enter Pincode"
                  value={PinCode}
                  onChange={(e) => {
                    setPinCode(e.target.value);
                  }}
                  required
                  className="online-darbar-form black1  half-input"
                />
                <div className="privacy-policy">
                  <input value="test" type="checkbox" onChange={handleChange} />
                  <Link to={"/privacy_policy"}>
                    <label htmlFor="">
                      <span className="highlight" title="Read Privacy Policy.">
                        Read{" "}
                      </span>
                      and
                      <span className="highlight"> Accept </span> Privacy Policy
                    </label>
                  </Link>
                </div>
              </div>
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
