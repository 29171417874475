import React from "react";
import { useSearchParams } from "react-router-dom";
import PageWelcomeBanner from "../../Components/web/SubComponents/PageWelcomeBanner";
import BlogPanel from "../../Components/web/BlogPanel";
import "../../All_css/blogSingleView.css";

const BlogSingleView = ({ blogsdb, logo_banner }) => {
  const [params] = useSearchParams();

  let newSingleBlog = blogsdb.filter((value, index) => {
    return value._id === params.get("blogId");
  });
  return (
    <div>
      <PageWelcomeBanner currentPage={"BLOGS"} logo_banner={logo_banner} />
      {params.get("blogId") !== undefined &&
        params.get("blogId") !== null &&
        params.get("blogId") !== "" &&
        newSingleBlog.map((value, index) => {
          return (
            <div id="single-blog">
              <div className="div-1">
                <img src={value.img_path} alt="" />
              </div>

              <div className="div-2">
                <div className="blogger-div">
                  <img src={value.blogger_image} alt="" />
                  <h4>{value.blogger_name}</h4>
                </div>
                <div className="single-blog-data">
                  <h3>{value.title}</h3>
                  <p>{value.description}</p>
                </div>
              </div>
            </div>
          );
        })}
      <BlogPanel blogsdbdata={blogsdb} paginateStatus={true} />
    </div>
  );
};

export default BlogSingleView;
