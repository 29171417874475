import React from "react";

const Privacy_policy = () => {
  return (
    <div className="container_div" style={{ paddingTop: "40px" }}>
      <div className="home_page_heading">
        {/* <h3>Journey</h3> */}
        <h1>Privacy Policy</h1>
      </div>
      <div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>1. Introduction:</h4>
          <p>
            This Privacy Policy document constitutes an agreement between
            ntplroots marketing management services pvt ltd ("NTPLROOTS,"
            "Company," "us," "we," and "our") and you ("Volunteer," "user,"
            "you," and "your"). Your privacy is important to us, and this
            Privacy Policy shall govern your access to and usage of this online
            platform "DR Kirodi Lal Website" and associated software
            ("Website"). By visiting the DR Kirodi Lal Website, you are
            accepting and consenting to the policy usage as described in this
            Privacy Policy. Please read this Privacy Policy to learn about our
            Information (as defined in Clause 4.1 below) gathering and
            dissemination practices.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>2. Consent:</h4>
          <p>
            This Privacy Policy is being framed in view of the Information
            Technology Act, 2000, read with Rule 4 of the Information Technology
            (Reasonable security practices and procedures and sensitive personal
            data or information) Rules, 2011, and Regulation 3(1) of the
            Information Technology (Intermediaries Guidelines) Rules, 2011. This
            Privacy Policy is incorporated into and subject to the Terms and
            Conditions applicable to you. Capitalized terms that are used and
            not defined in this privacy policy have the meaning given to them in
            the Terms and Conditions. Merely by using the DR Kirodi Lal Website,
            it is understood that you have read, understood, and agreed to the
            terms outlined in this Privacy Policy, and you agree to be bound by
            this Privacy Policy. You hereby consent to our collection, and use,
            of your Information as described in this Privacy Policy. We reserve
            the right to change, modify, add, or delete clauses from this
            Privacy Policy, at our absolute discretion, at any time, without any
            notice whatsoever. In case you wish to be aware of any changes, it
            is suggested that you review this Privacy Policy periodically. If at
            any point in time you do not agree with the terms and conditions of
            this Privacy Policy, refrain from using or accessing the DR Kirodi
            Lal Website. If you use the DR Kirodi Lal Website on behalf of
            someone else, including but not limited to, using on behalf of your
            minor child/employer, you represent and warrant that you are
            authorized by such person to accept this Privacy Policy on their
            behalf and to consent on behalf of such person to our use of such
            person's Information as described in this Privacy Policy.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>
            3. Access to your personal information
          </h4>
          <p>
            The Company will provide you with the means to ensure that your
            personal information is correct and current. If you have filled out
            a user profile, we will provide an obvious way for you to access and
            change your profile from our DR Kirodi Lal Website. You may at any
            time withdraw the consent provided by you, permitting us to collect
            and store your personal information. However, upon withdrawing your
            consent, you will no longer be able to access the DR Kirodi Lal
            Website. Your right to review, update, correct, and delete your
            personal information may be limited, subject to the law of your
            jurisdiction: if your requests are abusive or unreasonably
            excessive; where the rights or safety of another person or persons
            would be encroached upon; if the information or material you request
            relates to existing or anticipated legal proceedings between you and
            us, or providing access to you would prejudice negotiations between
            us or an investigation of possible unlawful activity.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>4. Collection of Information</h4>
          <p>
            'Information,' for the purposes of this Privacy Policy, shall mean
            and include, but not be limited to, the following personal
            information that you provide during registration with the DR Kirodi
            Lal Website: Name Gender Mobile Number Address for Correspondence
            Email address Date of Birth Identity Proofs – Voter ID/Driving
            License/Permanent Account Number Personal Social Media Platforms
            Details Constituency Details Other data that could directly or
            indirectly identify you; and Other information that you voluntarily
            choose to provide (like any information shared by you with us
            through emails or letters) The Information collected from you by us
            may be considered as 'personal information or 'sensitive personal
            data or information under the Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal
            Information) Rules, 2011. During your usage of the DR Kirodi Lal
            Website, personal information which is provided by you is collected
            and stored by us, mainly, to ensure that you enjoy a safe,
            effective, and customized experience. The collection and storage of
            your Information also enable us to provide various customized
            features that meet your particular needs. As a user, you always have
            the option to not provide information by choosing not to use a
            specific feature on the DR Kirodi Lal Website. Where applicable, we
            also indicate compulsory and optional fields. Based on your
            continual behavior on the DR Kirodi Lal Website, we may
            automatically track certain information about you to do internal
            research on users' demographics and particular interests, to serve
            you in a better manner. To monitor and analyze the usage flow,
            customize promotional features, and promote safety, we use data
            collection files such as "cookies" and pixel tags, and clear gifs on
            certain pages of the DR Kirodi Lal Website. "Cookies" are small
            files that are stored on a user's Device (Device shall mean and
            include, but not be limited to either a computer or a mobile phone
            or any other device using which the DR Kirodi Lal Website is
            accessed). They are designed to hold a marginal amount of data
            specific to a particular user and website and can be accessed either
            by the web server or the user device. This allows the server to
            deliver a page customized to a particular user. Personal Information
            of the user is not stored by the said cookies. We may also associate
            location data with your device ID and the Information we hold about
            you. In case your browser permits, you may decline our cookies;
            however, if you decline our cookies, you may be unable to use
            certain features on the DR Kirodi Lal Website, and you may be
            required to re-enter your password frequently. You may opt to leave
            them turned on. Cookies may be placed on the DR Kirodi Lal Website
            by third parties as well, the use of which we do not control. When
            you post messages on our message panels or give us any feedback
            through mail, etc., we will collect such Information provided to us.
            This Information may be retained by us to resolve
            disputes/issues/concerns, needs, grant user support, and
            troubleshoot problems as allowed under permissible law. Your account
            registration Information is/password protected, and only you can
            access your Information. The DR Kirodi Lal Website is not
            responsible for any activity that is undertaken when your password
            is used by anyone other than you. It is highly recommended that you
            not disclose your password to anyone. This Privacy Policy applies to
            DR Kirodi Lal Website. The Company does not exercise control over
            the websites displayed as search results or links from within the DR
            Kirodi Lal Website. The Company is not liable for any actions of
            such sites. This Privacy Policy also does not relate to any
            operating system used by a user to access the DR Kirodi Lal Website,
            and for any information which may be stored/collected/used by such
            operating system, the Company shall in no way be responsible/liable.
            The Company does not make any representation related to the privacy
            practices or policies of such third parties or terms of use of such
            websites, and the Company does not guarantee the accuracy or quality
            of the Information, software, sound, graphics, videos, messages, or
            other materials available on such websites. Simply by virtue of
            their inclusion in the DR Kirodi Lal Website, it must not be
            understood that the Company is endorsing the website, the website's
            provider, or the information contained therein. Visiting a
            third-party website linked to the DR Kirodi Lal Website is solely
            and completely done at your own risk. Kindly read the privacy policy
            of such third-party websites before using such party websites or
            apps.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>5. Using of Information</h4>
          <p>
            The Company may use your personal information for carrying out the
            following functions: Communication through Email, SMS, Calls, IVR,
            WhatsApp, and social media platforms; Process and respond to your
            queries; To credit reward points for the Tasks completed by you; For
            any other purpose with your consent. This is also to inform you that
            your information will never be shared with any third party. The
            Company collects only such personal information that is necessary
            and relevant for the performance of Tasks by you. Other than as
            specifically stated in this Privacy Policy, the Company will only
            disclose your information if it is required to do so by law or
            similar requirements or when the Company, in its exclusive
            discretion, finds it necessary to do so, to ensure that its rights
            or rights of others are protected, to prevent harm to persons or
            property, to counter fraud and credit-related risks, or to enforce
            the Terms and Conditions.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>6. Securing your data</h4>
          <p>
            The Company has in place standard security policies and technical
            measures to store in secured servers and protect the information
            that is possessed by your use of the DR Kirodi Lal Website, from any
            unauthorized access, improper collection, use, or disclosure,
            unlawful modification, and illegal destruction or inadvertent loss.
            However, for any loss or theft of Information due to unauthorized
            access to the user's devices through which the user accesses the DR
            Kirodi Lal Website, the Company shall not be held liable for any
            loss incurred by the user. The Company has reasonable security
            practices in terms of Rule 8 of the Information Technology
            (Reasonable security practices and procedures and sensitive personal
            data or information) Rules, 2011 and has a comprehensive documented
            Information Security Programme consisting of operational and
            physical security control measures to safeguard the information
            being collected.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>7. Confidentiality</h4>
          <p>
            It is important for you to be protected against unauthorized access,
            your password, and your Device. Kindly ensure that you log off from
            the DR Kirodi Lal Website once you finish using it. The Company does
            not undertake any liability for any unauthorized use of your account
            and password. If you suspect any unauthorized use of your account,
            immediately notify the Company by sending an email to the Company.
            You shall be liable to indemnify the Company due to any loss
            suffered by them due to such unauthorized use of your account and
            password. The Company shall not be responsible for any
            confidentiality, security, or distribution of your Information by
            the Company outside the scope of our agreement. Further, the Company
            shall not be responsible for any breach of security or for any
            actions of any third parties or events that are beyond the
            reasonable control of the Company, including but not limited to
            governmental acts, unauthorized access to Device data and storage
            devices, crashes, breach of security/encryption, Internet service or
            telephone service-related issues, etc.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>
            8. Compliance with legal, regulatory, and law enforcement requests
          </h4>
          <p>
            The Company will cooperate with law enforcement and government
            officials and private parties to comply with Applicable Law. The
            Company will, in its sole discretion, disclose any information about
            you to such parties as necessary to respond to claims and legal
            processes, to protect our property and rights or the property and
            rights of a third party, to protect the safety of the public or an
            individual, or to prevent or stop illegal or unethical activity. If
            the Company is legally permitted to do so, we will take reasonable
            steps to notify you in the event we are required to provide your
            information to third parties as part of a legal process.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>9. Grievance Officer</h4>
          <p>
            As per the Information Technology Act, 2000, and rules made
            thereunder, we are required to maintain a Grievance Officer.
            Accordingly, the name and contact details of the Grievance Officer
            are provided herein. If you find any discrepancies or have any
            grievances in relation to the collection, storage, use, disclosure,
            and transfer of your Information under this Privacy Policy or Terms
            and Conditions, please contact the following: Name: Aklesh Meena
            E-mail: Help@drkirodilal.in The details of the grievance officer may
            be changed by us from time to time by updating this Privacy Policy.
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>10. YouTube Privacy Policy</h4>
          <p>
            We use YouTube Data APIs to help users manage their YouTube
            accounts. And we follow YouTube and Google policy when using these
            APIs. Users can revoke the access of our application to their
            account via the Google security settings page at
            <a href="https://security.google.com/settings/security/permissions">
              https://security.google.com/settings/security/permissions
            </a>{" "}
            . Google Privacy Policy:{" "}
            <a href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>{" "}
            By using the application, users agree to be bound by the YouTube
            Terms of Service:{" "}
            <a href="https://www.youtube.com/t/terms">
              https://www.youtube.com/t/terms
            </a>
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>11. Twitter Privacy Policy</h4>
          <p>
            We use Twitter APIs to help users tweet or retweet a tweet on
            Twitter. We follow Twitter's privacy policy while using these APIs.
            Twitter's privacy policy can be accessed here.
            <a href="https://twitter.com/en/privacy">
              https://twitter.com/en/privacy
            </a>
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>12. Facebook Privacy Policy</h4>
          <p>
            We use Facebook APIs to help users like a post or follow a page. We
            follow Facebook's privacy policy while using these APIs. Facebook's
            privacy policy can be accessed in detail here.
            <a href="https://www.facebook.com/about/privacy/">
              https://www.facebook.com/about/privacy/
            </a>
          </p>
        </div>
        <div>
          <h4 style={{ fontWeight: "bold" }}>
            Thank you for using the DR Kirodi Lal Website!
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Privacy_policy;
