import React from "react";
import bannerImg from "../../../my_assets/app_requirements/indian-flag-tricolor-india-flag.jpg";
import "../../../All_css/pageWelcomeBanner.css";

export default function PageWelcomeBanner({ currentPage, logo_banner }) {
  let page = currentPage;
  return (
    <div
      style={{
        // eslint-disable-next-line no-undef
        fontFamily: "font-family: 'Anton', sans-serif !important",
        backgroundImage: `url(${
          logo_banner ? logo_banner.banner_img : bannerImg
        })`,
      }}
      className="page-welcome-banner"
    >
      <div style={{ textAlign: "center" }}>
        <h1 style={{ color: "white" }}>{page}</h1>
        <p style={{ textAlign: "center", fontSize: "0.8rem" }}>
          <span style={{ color: "white" }}>Home {">"} </span>
          <span style={{ color: "white" }}>{page}</span>
        </p>
      </div>
    </div>
  );
}
