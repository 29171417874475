import React from 'react'
import BlogPanel from '../Components/web/BlogPanel'
import PageWelcomeBanner from '../Components/web/SubComponents/PageWelcomeBanner'

export default function Blog({blogsdb ,logo_banner}) {
  return (
    <div>
      <PageWelcomeBanner currentPage={"BLOGS"} logo_banner={logo_banner} />
      <BlogPanel blogsdbdata={blogsdb} paginateStatus={true} />
    </div>
  )
}
