import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import logo from "./my_assets/app_requirements/Baba New PNG.png";

import "./App.css";
import MasterLayout from "./Layout/MasterLayout";

function App() {
  const [verifyAdmin, setVeryfyAdmin] = useState("LOADING");

  useEffect(() => {
    // fetching user's admin id -start

    // note replace uuu with below code
    //  ${window.location.hostname}
    // console.log(
    //   `${process.env.REACT_APP_SERVERPORT1}/getadminid/${process.env.REACT_APP_USERYUPP111}/${window.location.hostname}/${process.env.REACT_APP_USERYUPP2GJFG}`
    // );
    fetch(
      `${process.env.REACT_APP_SERVERPORT1}/getadminid/${process.env.REACT_APP_USERYUPP111}/${window.location.hostname}/${process.env.REACT_APP_USERYUPP2GJFG}`,
      {
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((pdata) => {
        if (pdata.message === "success") {
          sessionStorage.setItem(
            process.env.REACT_APP_USERNONECLIMATE,
            pdata.id
          );

          setVeryfyAdmin(process.env.REACT_APP_USERURIMGS);
        } else {
          setVeryfyAdmin("ERROR");
        }
      });
  });

  // fetching user's admin id -ends
  if (verifyAdmin === "LOADING") {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h4
          style={{
            display: "block",
            color: "#0026ff",
            textDecoration: "underline",
            padding: "30px",
          }}
        >
          <img
            style={{
              width: "300px",
              background: "#475284",
              padding: "30px",
              borderRadius: "10px",
            }}
            src={logo}
            alt=""
          />
        </h4>
        <br />
        <br />
        <p> Loading ... </p>
      </div>
    );
  }

  if (verifyAdmin === process.env.REACT_APP_USERURIMGS) {
    return (
      <div className="App">
        <BrowserRouter>
          <MasterLayout />
        </BrowserRouter>
      </div>
    );
  }
  if (verifyAdmin === "ERROR") {
    return <div>Internal Server Error!</div>;
  }
}

export default App;

// {
//   _id: ObjectId("64d2743265c06a449ed99020"),
//   name: 'userfrontend.substore.in',
//   email: 'clientemail@gmail.com',
//   username: 'clientusername',
//   type: 'admin',
//   client_name: 'client name',
//   mob_no: '1234567890',
//   watsapp_no: '1234567890',
//   client_dob: '2023-08-10',
//   client_age: '00',
//   client_address: 'the address of client',
//   constituency_Type: 'Panchayat',
//   constituency_Area: 'the constituency area',
//   autologinkey: '',
//   password: '$2b$10$6vifHlXE6yegX6TgPygZ4.nFJRYaWYH9MHGd1gxjaqZnphitaiPo.',
//   __v: 0,
//   adhar_back: 'http://backend.substore.in/clientmedia/64d2743265c06a449ed99020/documents/69882869205092f3702ef9724c6ff8624dbbd.jpeg',
//   adhar_front: 'http://backend.substore.in/clientmedia/64d2743265c06a449ed99020/documents/69882869205092f3702ef9724c6ff8624dbbd.jpeg',
//   profile_img: 'http://backend.substore.in/clientmedia/64d2743265c06a449ed99020/documents/69882ee58f6837f881ee9e8fb133f0412021c.jpeg'
// }
