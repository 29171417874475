/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../Pages/Home";
import Journey from "../Pages/Journey";
import MediaCoverage from "../Pages/MediaCoverage";
import OnlineDarbar from "../Pages/OnlineDarbar";
import Governance from "../Pages/Governance";
import Blog from "../Pages/Blog";
import BlogSingleView from "../Pages/singleView/BlogSingleView";
import Issue from "../Pages/Issue";
import Gallery from "../Pages/Gallery";
import Events from "../Pages/Events";
import Privacy_policy from "../Components/web/Privacy_policy";
import Dr_kirodilal_app_privacy_policy from "../Components/web/DrKirodilalApp_privacy_policy";
import AccountDeleteRequest from "../Components/web/AccountDeleteRequest";

export default function Routing({
  setfooterData,
  setsocialMediaLinks,
  setlogo_banner,
  logo_banner,
}) {
  //--------------------- database data fetching - start-------------------------------------------

  // function to get data from database -start}
  const getDatabaseData = async (url, setFunction) => {
    // console.log(
    //   `${process.env.REACT_APP_SERVERPORT1}/${sessionStorage.getItem(
    //     process.env.REACT_APP_USERNONECLIMATE
    //   )}/${process.env.REACT_APP_USERVIRAKORE}/` + url
    // );
    fetch(
      `${process.env.REACT_APP_SERVERPORT1}/${sessionStorage.getItem(
        process.env.REACT_APP_USERNONECLIMATE
      )}/${process.env.REACT_APP_USERVIRAKORE}/` + url
    )
      .then((response) => response.json())
      .then((pdata) => {
        setFunction(pdata);
      })
      .catch((err) => {
        setFunction([]);

        console.log(err.message);
      });
  };
  // function to get data from database -start}

  // all database states

  //----------------------------------------------------------------
  // Website data getting -start
  const [storydb, setstorydb] = useState([]);
  const [bannerdb, setbannerdb] = useState([]);
  const [newsdb, setnewsdb] = useState([]);
  const [blogsdb, setblogsdb] = useState([]);
  const [eventsdb, seteventsdb] = useState([]);
  const [gallerydb, setgallerydb] = useState([]);
  const [issuesdb, setissuesdb] = useState([]);
  const [adminAboutdb, setadminAboutdb] = useState({});
  const [Journeydb, setJourneydb] = useState([]);
  const [governancedb, setgovernancedb] = useState([]);
  const [all_issuedb, setall_issuedb] = useState([]);

  let allDbs = {
    storydb: storydb,
    bannerdb: bannerdb,
    newsdb: newsdb,
    blogsdb: blogsdb,
    eventsdb: eventsdb,
    gallerydb: gallerydb,
    socialmediadb: issuesdb,
    adminAboutdb: adminAboutdb,
    Journeydb: Journeydb,
    governancedb: governancedb,
    all_issuedb: all_issuedb,
  };

  // wrapping all in one object

  // Website data getting -ends
  //----------------------------------------------------------------

  useEffect(() => {
    getDatabaseData(`getStory`, setstorydb);
    getDatabaseData(`getBanner`, setbannerdb);
    getDatabaseData(`getNews`, setnewsdb);
    getDatabaseData(`getBlogs`, setblogsdb);
    getDatabaseData(`getEvents`, seteventsdb);
    getDatabaseData(`getGallery`, setgallerydb);
    // here issue db represent the  social media database
    getDatabaseData(`getIssues`, setissuesdb);
    getDatabaseData(`getIssues`, setsocialMediaLinks);
    getDatabaseData(`getAdminAboutDetails`, setadminAboutdb);
    getDatabaseData(`getAdminAboutDetails`, setfooterData);
    getDatabaseData(`getJourney`, setJourneydb);
    getDatabaseData(`getgovernance`, setgovernancedb);
    getDatabaseData(`getall_issues`, setall_issuedb);
    getDatabaseData(`getlogo_banner`, setlogo_banner);
  }, [setfooterData, setlogo_banner, setsocialMediaLinks]);
  //--------------------- database data fetching - ends-------------------------------------------

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home dbData={allDbs} />} />
        <Route
          path="/journey"
          element={
            <Journey
              adminAboutdb={adminAboutdb}
              Journeydb={Journeydb}
              logo_banner={logo_banner}
            />
          }
        />
        <Route
          path="/media_coverage"
          element={<MediaCoverage newsdb={newsdb} logo_banner={logo_banner} />}
        />
        <Route
          path="/drkirodilalapp"
          element={
            <OnlineDarbar
              adminAboutdb={adminAboutdb}
              logo_banner={logo_banner}
            />
          }
        />
        <Route
          path="/dr_kirodilal_app_privacy_policy"
          element={
            <Dr_kirodilal_app_privacy_policy
              adminAboutdb={adminAboutdb}
              logo_banner={logo_banner}
            />
          }
        />
        <Route
          path="/account_and_data_delete_request"
          element={
            <AccountDeleteRequest
              adminAboutdb={adminAboutdb}
              logo_banner={logo_banner}
            />
          }
        />
        <Route
          path="/governance"
          element={
            <Governance governancedb={governancedb} logo_banner={logo_banner} />
          }
        />
        <Route
          path="/blog"
          element={<Blog blogsdb={blogsdb} logo_banner={logo_banner} />}
        />
        <Route
          path="/blog_view"
          element={
            <BlogSingleView blogsdb={blogsdb} logo_banner={logo_banner} />
          }
        />

        <Route
          path="/issues"
          element={
            <Issue all_issuesdb={all_issuedb} logo_banner={logo_banner} />
          }
        />
        <Route
          path="/gallery"
          element={<Gallery gallerydb={gallerydb} logo_banner={logo_banner} />}
        />
        <Route
          path="/events"
          element={<Events eventsdb={eventsdb} logo_banner={logo_banner} />}
        />
        <Route path="/privacy_policy" element={<Privacy_policy />} />
      </Routes>
    </div>
  );
}
