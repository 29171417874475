import React from "react";
import { Link } from "react-router-dom";

export default function ViewAllBtn({ page, bgcolor }) {
  return (
    <div
      style={{
        textAlign: "center",
        padding: "45px",
        backgroundColor: bgcolor ? bgcolor : "white",
      }}
    >
      <Link
        style={{
          textDecoration: "none",
          margin: "28px 0",
          padding: "11px 42px",
          border: "none",
          color: "white",
          backgroundColor: "#3c486b",
          borderRadius:"50px",
        }}
        to={page}
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        View All
      </Link>
    </div>
  );
}
