import React from "react";
import OnlineDarbarPanel from "../Components/web/OnlineDarbarPanel";
// import IssuesPanel from '../Components/web/IssuesPanel'
import PageWelcomeBanner from "../Components/web/SubComponents/PageWelcomeBanner";
import MarketingOnlineDarbarApp from "../Components/web/MarketingOnlineDarbarApp";
import BlackOnlineDarbar from "../Components/web/BlackOnlineDarbar";

export default function OnlineDarbar({ adminAboutdb ,logo_banner }) {
  return (
    <div>
      <PageWelcomeBanner currentPage={"Dr kirodilal"} logo_banner={logo_banner} />
      <MarketingOnlineDarbarApp />
      <BlackOnlineDarbar adminAboutdb={adminAboutdb} />
    </div>
  );
}
