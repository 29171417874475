import React from "react";
import NewsPanel from "../Components/web/NewsPanel"
// import MediaCoveragePanel from "../Components/web/MediaCoveragePanel";

import PageWelcomeBanner from "../Components/web/SubComponents/PageWelcomeBanner";

export default function MediaCoverage({ newsdb ,logo_banner }) {
  return (
    <div>
      <PageWelcomeBanner currentPage={"MEDIA COVERAGE"} logo_banner={logo_banner} />
      {/* <NewsPanel newsdbdata={newsdb} paginateStatus={true} /> */}
      {/* <MediaCoveragePanel newsdbdata={newsdb} /> */}
      <NewsPanel newsdbdata={newsdb} paginateStatus={true} />
    </div>
  );
}
 