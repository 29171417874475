import React, { useState } from "react";

import ImageSlider from "../Components/web/ImageSlider";
import JourneyHomePanel from "../Components/web/JourneyHomePanel";
// import IssuesPanel from '../Components/web/IssuesPanel'
import IssueHomePanel from "../Components/web/issueHomePanel";
import BlogPanel from "../Components/web/BlogPanel";
import NewsPanel from "../Components/web/NewsPanel";
import GalleryPanel from "../Components/web/GalleryPanel";
import SocialMediaPage from "../Components/web/SocialMediaPage";
// import VolunteersPanel from '../Components/web/VolunteersPanel'
import OnlineDarbarPanel from "../Components/web/OnlineDarbarPanel";
import ViewAllBtn from "../Components/web/SubComponents/ViewAllBtn";
import GovernanceHomePanel from "../Components/web/GovernanceHomePanel";
import Events from "./Events";
import EventsPanel from "../Components/web/EventsPanel";

export default function Home({ dbData }) {
  const {
    eventsdb,
    socialmediadb,
    newsdb,
    blogsdb,
    gallerydb,
    bannerdb,
    adminAboutdb,
    Journeydb,
    governancedb,
    all_issuedb,
  } = dbData;

  return (
    <div>
      <ImageSlider dbBannerData={bannerdb} />

      <JourneyHomePanel Journeydb={Journeydb} />

      {/* --------------------------------------------- */}
      <IssueHomePanel issuesdbdata={all_issuedb.slice(0, 8)} />

      {/* --------------------------------------------- */}
      <OnlineDarbarPanel adminAboutdb={adminAboutdb} />
      {/* --------------------------------------------- */}
      <GovernanceHomePanel governancedata={governancedb} />
      {/* --------------------------------------------- */}
      <NewsPanel newsdbdata={newsdb.slice(0, 2)} paginateStatus={false} />
      <ViewAllBtn page={"media_coverage"} bgcolor={"#f3f3f3"} />
      {/* --------------------------------------------- */}

      {/* --------------------------------------------- */}
      <BlogPanel blogsdbdata={blogsdb.slice(0, 4)} paginateStatus={false} />
      <ViewAllBtn page={"blog"} bgcolor={"#f0f0f0"} />
      {/* --------------------------------------------- */}

      {/* --------------------------------------------- */}
      {/* <GalleryPanel gallerydbData={gallerydb.slice(0, 4)} /> */}
      {/* --------------------------------------------- */}

      {/* --------------------------------------------- */}
      {/* <Events eventsdb={eventsdb.slice(0,2)} />  */}
      <EventsPanel paginateStatus={false} eventsdbdata={eventsdb.slice(0, 2)} />
      <ViewAllBtn page={"events"} bgcolor={"#f3f3f3"} />
      {/* --------------------------------------------- */}

      <SocialMediaPage socialmediadb={socialmediadb} />
    </div>
  );
}
