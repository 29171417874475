import React, { useState, useEffect } from "react";
import "../../All_css/newGovernencePanel.css";

import GovernenceMovePage from "./SubComponents/GovernenceMovePage";

const NewGovernancePanel = ({ governancedb }) => {
  return (
    <div>
    
      {governancedb.map((value, index) => {
        return <GovernenceMovePage key={index} value={value} index={index} />;
      })}
    </div>
  );
};

export default NewGovernancePanel;
 