import React from 'react'
import AboutPanel from '../Components/web/AboutPanel1'
import JourneyPanel from '../Components/web/JourneyPanel'
import PageWelcomeBanner from '../Components/web/SubComponents/PageWelcomeBanner'

export default function Journey({adminAboutdb,Journeydb ,logo_banner}) {
  return (
    <div>
      <PageWelcomeBanner currentPage={"JOURNEY"} logo_banner={logo_banner} />
      <AboutPanel aboutData={adminAboutdb} />
      <JourneyPanel journeyData={Journeydb} />
    </div>
  )
}
 