import React, { useState } from "react";
import "../../All_css/NewsPanel.css";
import "../../All_css/pagination.css";
import ReactPaginate from "react-paginate";

export default function NewsPanel({ newsdbdata, paginateStatus }) {
  // Example items, to simulate fetching from other resources.

  function handleReadMORE(e) {
    e.target.parentElement.parentElement.children[2].style.display = "none";
    e.target.parentElement.parentElement.children[3].style.display = "block";
  }
  function handleReadLess(e) {
    e.target.parentElement.parentElement.children[2].style.display = "block";
    e.target.parentElement.parentElement.children[3].style.display = "none";
  }

  const items = newsdbdata;
  function PaginatedItems({ itemsPerPage }) {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      //   console.log(
      //     `User requested page number ${event.selected}, which is offset ${newOffset}`
      //   );
      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        {paginateStatus && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            className="react-paginate-navigator"
            onClick={window.scroll(0, 0)}
          />
        )}
      </>
    );
  }

  function Items({ currentItems }) {
    return (
      <div className="container_div">
        <div className="home_page_heading">
          {/* <h3>Media</h3> */}
          <h1>Media Coverage</h1>
        </div>
        <div>
          {/* {console.log(newsdbdata)} */}
          {currentItems.map((value, index) => {
            return (
              <div id="news_div1" key={index}>
                <div className="grid-section1">
                  <div
                    style={{
                      backgroundImage: `url(${value.img_path})`,
                    }}
                    className="handle_image_size_percent"
                  ></div>
                  {/* <img src={value.img_path} alt="" /> */}
                </div>
                <div className="grid-section2">
                  <h4 style={{ color: "#3c486b", fontWeight: "600" }}>
                    {value.title}
                  </h4>
                  <h5>{value.media_coverage}</h5>
                  <br />
                  <div>
                    <p style={{ fontSize: "0.9rem" }}>
                      {value.description && value.description.substr(0, 300)}
                    </p>
                    <a href={value.media_coverage_url}>
                      <button
                      // onClick={(e) => {
                      //   handleReadMORE(e);
                      // }}
                      >
                        READ MORE
                      </button>
                    </a>
                  </div>
                  <div style={{ display: "none" }}>
                    <p>{value.description && value.description}</p>
                    <button
                      onClick={(e) => {
                        handleReadLess(e);
                      }}
                    >
                      READ Less
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <PaginatedItems itemsPerPage={6} />
    </>
  );
}
