import React from "react";
import mobileImg1 from "../../my_assets/app_requirements/dumyMobileImg2.png";
import "../../All_css/MarketingOnlineDarbarAppPanel.css";

const MarketingOnlineDarbarApp = () => {
  // return <div>
  //   <img style={{width:"100vw", height:"100vh"}} src={mobileImg1} alt="" />
  // </div>;

  return (
    <div className="container_div black1">
      {/* <div className="home_page_heading">
        <h3>Online Darbar</h3>
        <h1>The Online Darbar Mobile App</h1>
      </div> */}
      <div className="app-marketing-main">
        {/* ------------------------- left ----------------------------------- */}

        <div className="app-card">
          <div className="app-image app-card-sec1">
            <img src={mobileImg1} alt="" />
          </div>
          <div className="app-description app-card-sec2">
            <div>
              <h3 style={{ textAlign: "center" }} className="color1">Dr Kirodilal App</h3>
              <p className="color1">
              "Dr. Kirodi Lal Meena" is a comprehensive and user-friendly mobile app dedicated to the renowned Indian politician, Dr. Kirodi Lal Meena. This app offers a wealth of information about his life, achievements, and contributions to Indian society and politics. Users can explore his biography, watch videos of his speeches and interviews, and access a library of articles and documents related to his political career. Stay updated with the latest news and events associated with Dr. Kirodi Lal Meena, making it an essential app for anyone interested in Indian politics and his legacy.
              </p>
              <div>  
                <a href="https://play.google.com/"><button><i class="material-icons">smart_display</i> PlayStore </button></a>
                <a href="https://www.apple.com/in/app-store/"><button><i class="material-icons">apple</i> Apple Store</button></a>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------------ */}
      </div>
    </div>
  );
};

export default MarketingOnlineDarbarApp;
