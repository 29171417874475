import React from "react";
import PageWelcomeBanner from "../Components/web/SubComponents/PageWelcomeBanner";
import EventsPanel from "../Components/web/EventsPanel";


const Events = ({eventsdb ,logo_banner}) => {
  return <div>
     <PageWelcomeBanner currentPage={"Events"}  logo_banner={logo_banner} />
     <EventsPanel paginateStatus={true} eventsdbdata={eventsdb}/>
  </div>;
};

export default Events;
