import React, { useState } from "react";
import "../../All_css/socialMediaPage.css";
import { InstagramEmbed } from "react-social-media-embed";
import { FacebookEmbed } from "react-social-media-embed";
import { TwitterEmbed } from "react-social-media-embed";

const SocialMediaPage = ({ socialmediadb }) => {
  const [currentPage, setcurrentPage] = useState(0);
  // https://www.youtube.com/watch?v=nF1fSKEiyA0
  function handleFrames() {
    if (socialmediadb[currentPage]) {
      if (socialmediadb[currentPage].description.includes("youtube")) {
        return (
          <iframe
            width="1013"
            height="570"
            src={socialmediadb[currentPage].description.replace(
              "watch?v=",
              "embed/"
            )}
            title="📚4-HOUR STUDY WITH ME / 🎹calm piano + gentle rain sound🌧 / Tokyo at RAINY MORNING / timer+bell"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        );
      } else if (socialmediadb[currentPage].description.includes("instagram")) {
        return (
          <div style={{ display: "flex", justifyContent: "center",overflow:"hidden" }}>
            <InstagramEmbed
              style={{ height: "80vh" }}
              // url="https://www.instagram.com/p/CUbHfhpswxt/"
              url={socialmediadb[currentPage].description}
              width={328}
            />
          </div>
        );
      } else if (socialmediadb[currentPage].description.includes("facebook")) {
        return (
          <div style={{ display: "flex", justifyContent: "center",overflow:"hidden" }}>
            <FacebookEmbed
              style={{ height: "80vh" }}
              url={socialmediadb[currentPage].description}
              width={550}
            />
          </div>
        );
      } else if (socialmediadb[currentPage].description.includes("twitter")) {
        return (
          <div style={{ display: "flex", justifyContent: "center" ,overflow:"hidden" }}>
            <TwitterEmbed
              style={{ height: "80vh" }}
              url={socialmediadb[currentPage].description}
              width={325}
            />
          </div>
        );
      } else {
        return (
          <iframe
            src={
              socialmediadb[currentPage] &&
              socialmediadb[currentPage].description
            }
            title={
              socialmediadb[currentPage] && socialmediadb[currentPage].title
            }
          ></iframe>
        );
      }
    } else {
      return <div>nothing to show</div>;
    }
  }

  function handleColor(name){
    if(name.toLowerCase() === "youtube"){
      return "red";
    }else if(name.toLowerCase() === "facebook"){
      return "#365194";
    }else if(name.toLowerCase() === "instagram"){
      return "#c62388";
    }else if(name.toLowerCase() === "twitter"){
      return "#1c9cea";
    }else {
      return "#3c486b"
    }
  }

  return (
    <div className="social-media-container">
      <div className="home_page_heading social-media-heading">
        {/* <h3>Social Media</h3> */}
        <h1>Our Social Media</h1>
      </div>
      <div>
        <div className="social-media-frames">{handleFrames()}</div>
        {/* {socialmediadb.filter((value, index) => {
          return  (
            
              <iframe src={value.description} title={value.title} frameborder="0"></iframe>
          
          );
        })} */}
      </div>
      <div className="socialMediaNav-div">
        <div className="socialMediaNav">
          {socialmediadb.map((value, index) => {
            return (
              <div
                key={index}
                className="socialMediaNav-item"
                style={{color:handleColor(value.title)}}
                onClick={() => {
                  setcurrentPage(index);
                  handleFrames();
                }}
              >
                {value.title}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

 

export default SocialMediaPage;
