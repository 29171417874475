import React from "react";
import "../../All_css/AboutPanel.css";
import { Link } from "react-router-dom";

export default function AboutPanel1({ aboutData }) {
  return (
    <div className="container_div">
      <div id="about_div1">
        <div className="grid-section1">
          <img
            src={aboutData && aboutData.img_path && aboutData.img_path}
            alt=""
          />
        </div>
        <div className="grid-section2">
          <h3>About Our Leader</h3>
          <h1>{aboutData && aboutData.name && aboutData.name}</h1>
          <br />
          <p>
            {window.location.href.includes("journey")
              ? aboutData && aboutData.description && aboutData.description
              : aboutData &&
                aboutData.description &&
                aboutData.description &&
                aboutData.description.substr(0, 500) + "..."}
          </p>
          {window.location.href.includes("journey") ? (
            ""
          ) : (
            <Link to="journey">
              {" "}
              <button>READ MORE</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
